.adManagerViewAd {
    width: 75% !important;
    background-color: #E7D62C;
  }
.adManagerShow {
    width:90px !important;
    margin:11px !important;
  }
.MuiTypography-root.adManagerMessage.MuiTypography-h6{
    margin-top: 10px !important;
    width:250px;
}
.adManagerButton{
    background-color: #069 !important;
    color: white !important;
    width: 120px;
    font-size: 10px !important;
    margin: 20px !important;
    margin-top:35px !important;
    border-radius: 0px !important;
  }
.adManagerButtonText{
    font-size: 10px !important;
    color:white;
  }
