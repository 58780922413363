a{
    text-decoration: none;
    color: black;
  }
  
  a:visited{
    color: black;
  }
  
  .box::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    border-radius: 5px
  }
  
  .box::-webkit-scrollbar
  {
      width: 10px;
      background-color: #F5F5F5;
    border-radius: 5px
  }
  
  .box::-webkit-scrollbar-thumb
  {
      background-color: black;
      border: 2px solid black;
    border-radius: 5px
  }
  
  header{
    -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    height: 110px;
    vertical-align: middle;
  }
  
  h1{
   float: left;
    padding: 10px 30px
  }
  
  body{
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
  }
  
  .icons{
    display: inline;
    float: right;
  }
  
  .notification{
    padding-top: 30px;
    position: relative;
    display: inline-block;
  }
  
  .number{
    height: 22px;
    width:  22px;
    background-color: #d63031;
    border-radius: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 13px;
    padding: 3px;
    border-style: solid;
    border-width: 2px;
  }
  
  .number:empty {
     display: none;
  }

  .btn{
    margin-left: 20px;
    margin-right: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    float: right;
    padding-top: 17px;
  }
  
  .notBtn{
    transition: 0.5s;
    display: flex;
    align-items: baseline;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .fas{
    font-size: 25pt;
    padding-bottom: 10px;
    color: black;
    margin-right: 40px;
    margin-left: 40px;
  }
  
  .box{
    width: 400px;
    height: 0px;
    border-radius: 10px;
    transition: 0.5s;
    position: absolute;
    overflow-y: scroll;
    padding: 0px;
    left: -300px;
    top:70px;
    margin-top: 5px;
    background-color: #F4F4F4;
    -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);
    cursor: context-menu;
    z-index: 100;
  }
  
  .fas:hover {
    color: #d63031;
  }
  
  .notBtn:hover > .box{
    height: 60vh
  }
  
  .content{
    padding: 20px;
    color: black;
    vertical-align: middle;
    text-align: left;
  }
  
  .gry{
    background-color: #F4F4F4;
  }
  
  .top{
    color: black;
    padding: 10px
  }
  
  .display{
    position: relative;
  }
  
  .cont{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
  }
  
  .cont:empty{
    display: none;
  }
  
  .stick{
    text-align: center;  
    display: block;
    font-size: 50pt;
    padding-top: 70px;
    padding-left: 80px
  }
  
  .stick:hover{
    color: black;
  }
  
  .cent{
    text-align: center;
    display: block;
  }
  
  .sec{
    padding: 25px 10px;
    background-color: #F4F4F4;
    transition: 0.5s;
  }
  
  .profCont{
    padding-left: 15px;
  }
  
  .profile{
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 75px;
    float: left;
  }
  
  .txt{
    vertical-align: top;
    font-size: 1.25rem;
    /* padding: 5px 10px 0px 115px; */
  }
  
  .sub{
    font-size: 1rem;
    color: grey;
  }
  
  .new{
    border-style: none none solid none;
    border-color: red;
  }
  
  .sec:hover{
    background-color: #BFBFBF;
  }
.landing-page-header{
  float: right;
}
  
  
  